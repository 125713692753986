import { InitStyle } from "./style";
const InitPage = () => { 
    return (
        <>
        <InitStyle/>
    <div className="Home">
        THE BIBLE
        </div>
        </>
    )
}
export default InitPage;